const TestNet = require('./testnet.json')
const MainNet = require('./mainnet.json')

const testnet = {
    id: 1176455790972829965191905223412607679856028701100105089447013101863,
    name: 'vechain_testnet',
    network: 'homestead',
    nativeCurrency: {
        name: 'VeChainThor',
        symbol: 'VET',
        decimals: 18,
    },
    rpcUrls: {
        public: {
            http: ["https://node-testnet.vechain.energy"]
        },
        default: {
            http: ["https://node-testnet.vechain.energy"]
        }
    },
    contracts: {
        ...TestNet.contracts,
        venOracle: {
            address: '0xdcCAaBd81B38e0dEEf4c202bC7F1261A4D9192C6'
        }
    },
    batch: { multicall3: true },
    blockExplorers: {
        default: { name: 'Explorer', url: 'https://explore-testnet.vechain.org' },
    },
}

const mainnet = {
    id: 14018334920824264832118464179726739019961432051877733167310318607178,
    name: 'vechain_mainnet',
    network: 'homestead',
    nativeCurrency: {
        name: 'VeChainThor',
        symbol: 'VET',
        decimals: 18,
    },
    rpcUrls: {
        public: {
            http: ["https://node-mainnet.vechain.energy"]
        },
        default: {
            http: ["https://node-mainnet.vechain.energy"]
        }
    },
    contracts: {
        ...MainNet.contracts,
        venOracle: {
            address: '0x49eC7192BF804Abc289645ca86F1eD01a6C17713'
        }
    },
    batch: { multicall3: true },
    blockExplorers: {
        default: { name: 'Explorer', url: 'https://vechainstats.com' },
    },
}

export { testnet, mainnet }