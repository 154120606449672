import { useWallet } from '@vechain/dapp-kit-react'
import { useWalletName } from "~/src/hooks/useWalletName";

export default function AppHub() {
    const { account } = useWallet()
    const { name } = useWalletName(account);

    return (
        <div className="bg-background">
            <div className="px-6 py-4 sm:px-6 sm:py-16 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <div className='w-32 h-32 m-auto pb-8 pt-8'><span className='text-6xl'>🎉</span></div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                        Welcome to <a className='hover:underline' href="https://x.com/search?q=%23VeFam" target="_blank" rel="noopener noreferrer">#VeFam</a> {name}!
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-500">
                        You can now explore Vechain on your own. The best place to start is the VeBetterDAO, which is a great way to immerse yourself in the core values of Vechain.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="https://VeBetterDAO.org"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="w-full h-12 flex items-center justify-center rounded-full bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            Open VeBetterDAO.org
                        </a>

                    </div>

                    <p className="mx-auto mt-6 max-w-xl text-sm italic leading-5 text-gray-400 dark:text-gray-500">
                        Did you notice that your name claim was free of gas fee headaches?<br />
                        Fee Delegation allows others to cover your transaction costs, turning blockchain apps into regular websites.
                        Your .vet name even includes free transactions, enabling a full Vechain experience.
                        Discover more at <a className='text-orange-500 dark:text-orange-600 hover:underline' href="https://docs.vet.domains/Guides/Free-Transactions/" target="_blank" rel="noreferrer noopener">vet.domains</a>.
                    </p>

                </div>
            </div>
        </div>
    )
}
