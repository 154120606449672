import React from 'react'
import { useParams } from 'react-router-dom'
import { ExclamationCircleIcon as IconError, CheckCircleIcon as IconSuccess } from '@heroicons/react/20/solid'
import { useEns } from "../hooks/useEns"
import { namehash, id, Interface } from 'ethers'
import { useConnex } from '@vechain/dapp-kit-react'
import { cn } from '@/lib/utils'
import { ens_normalize } from '@adraffy/ens-normalize'
import { API_URL } from '~/src/config'
import { useWallet } from '@vechain/dapp-kit-react'
import Loading from '~/src/common/Loading'
import { SPONSORSHIP_URL } from '~/src/config'
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const Registrar = new Interface([
    'function register(bytes, bytes)',
    'function setAddr(bytes32, address)',
    'function setName(string)',
    'function unwrapETH2LD(bytes32, address, address)'
])

export default function ClaimName() {
    const [username, setUsername] = React.useState('')
    const { client } = useEns()
    const connex = useConnex()
    const [isAvailable, setIsAvailable] = React.useState(false)
    const [usernameError, setUsernameError] = React.useState('')
    const [txError, setTxError] = React.useState('')
    const [statusMessage, setStatusMessage] = React.useState('')
    const { account } = useWallet()
    const [loading, setLoading] = React.useState(false)
    const params = useParams()

    React.useEffect(() => {
        setUsernameError('')

        if (!username) { return }
        setLoading(true)
        try {
            const normalized = ens_normalize(username)
            if (normalized.length < 5) { throw new Error('Name must be at least 5 characters.') }

            const tokenId = id(normalized)
            connex.thor
                .account(client.chain.contracts.ensBaseRegistrarImplementation.address)
                .method({
                    "name": "available",
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "id",
                            "type": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "type": "function"
                })
                .call(tokenId)
                .then(result => {
                    setIsAvailable(BigInt(result.data) === BigInt(1))
                })
        }
        catch (err: any) {
            setUsernameError(err.message)
            setIsAvailable(false)
        }
        finally {
            setLoading(false)
        }
    }, [client, username])

    const handleClaim = async () => {
        if (!account) { return }
        setTxError('')
        setLoading(true)
        setStatusMessage('Preparing transaction...')
        try {
            const signature = await fetch(`${API_URL}/api/claim/onboarding`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: username,
                    sender: client.chain.contracts.vetSignedRegistrarController.address,
                    resolver: client.chain.contracts.ensPublicResolver.address,
                    activity: params.activity
                })
            })
                .then(res => res.json())
            if (signature?.errorMessage) throw new Error(signature.errorMessage)

            const fullName = [username, 'vet'].join('.')
            const node = namehash([username, 'vet'].join('.'))
            const labelhash = id(username)
            const tx = await connex.vendor.sign('tx',
                [
                    {
                        to: client.chain.contracts.vetSignedRegistrarController.address,
                        data: Registrar.encodeFunctionData('register', [signature.data, '0x']),
                        value: '0x0'
                    },
                    {
                        to: client.chain.contracts.ensPublicResolver.address,
                        data: Registrar.encodeFunctionData('setAddr', [node, account]),
                        value: '0x0'
                    },
                    {
                        to: client.chain.contracts.ensReverseRegistrar.address,
                        data: Registrar.encodeFunctionData('setName', [fullName]),
                        value: '0x0'
                    },
                    {
                        to: client.chain.contracts.ensNameWrapper.address,
                        data: Registrar.encodeFunctionData('unwrapETH2LD', [labelhash, account, account]),
                        value: '0x0'
                    }
                ]
            )
                .signer(account)
                .delegate(SPONSORSHIP_URL)
                .request()

            await waitForTxId(tx.txid)
            location.reload()
        }
        catch (err: any) {
            setTxError(err.message)
        }
        finally {
            setStatusMessage('')
            setLoading(false)
        }
    }


    const waitForTxId = async (txId: string, options?: { successMessage?: string }) => {
        setStatusMessage(`Transaction ${txId.slice(0, 4)}..${txId.slice(-4)} sent, waiting for confirmation`)

        let txReceipt
        const tx = connex.thor.transaction(txId)
        do {
            await sleep(1000)
            txReceipt = await tx.getReceipt()
            if (txReceipt?.reverted) {
                setStatusMessage(`Transaction failed, please try again later`)
                return false
            }
            else if (txReceipt) {
                if (options?.successMessage) {
                    setStatusMessage(options.successMessage)
                }
                return true
            }
        } while (!txReceipt)

        return false
    }


    return (
        <div className="bg-background">
            <div className="px-6 py-4 sm:px-6 sm:py-16 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <div className='w-32 h-32 m-auto pb-8 pt-8'><span className='text-6xl'>👋</span></div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                        Personalize Your Wallet
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-500">
                        As a welcome gift, you can claim a free .vet name from Vechain's name service <a className='text-orange-500 dark:text-orange-600 hover:underline' href="https://vet.domains">vet.domains</a>. It will serve as your unique identity on the Vechain blockchain and also covers your transaction fees.
                    </p>

                    <div className='text-left'>
                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="flex rounded-md shadow-sm">
                                <input
                                    type="username"
                                    name="username"
                                    id="username"
                                    className={
                                        cn(
                                            "block w-full h-12 rounded-l-md border-0 px-2 py-1.5 pr-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6",
                                            (!Boolean(username) || isAvailable) ? "" : "text-red-900 dark:text-red-300 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                        )
                                    }
                                    placeholder="Your-Free-Name"
                                    value={username}
                                    aria-invalid={!isAvailable}
                                    aria-describedby="username-error"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                {Boolean(username) && !isAvailable && (
                                    <div className="pointer-events-none absolute inset-y-0 right-11 flex items-center pr-3">
                                        <IconError className="h-6 w-6 text-red-500" aria-hidden="true" />
                                    </div>
                                )}
                                {Boolean(username) && isAvailable && !loading && (
                                    <div className="pointer-events-none absolute inset-y-0 right-11 flex items-center pr-3">
                                        <IconSuccess className="h-6 w-6 text-green-500" aria-hidden="true" />
                                    </div>
                                )}
                                {Boolean(username) && loading && (
                                    <div className="pointer-events-none absolute inset-y-0 right-11 flex items-center pr-3">
                                        <Loading active />
                                    </div>
                                )}
                                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 text-gray-500">
                                    .vet
                                </span>
                            </div>
                        </div>
                        <p className="mt-2 text-sm text-red-600 h-4" id="username-error">
                            {Boolean(username) && !isAvailable && (
                                <>
                                    {usernameError || 'Please try a different name.'}
                                </>
                            )}
                        </p>
                    </div>

                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <button
                            type='button'
                            onClick={() => void handleClaim()}
                            className={
                                cn(
                                    "w-full h-12 flex items-center justify-center  rounded-full bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 flex space-x-2 items-center",
                                    (isAvailable && !loading) ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                                )
                            }
                            disabled={!isAvailable || loading}
                        >
                            <span>Claim My Name</span>
                            {loading && <Loading active={loading} />}
                        </button>
                    </div>

                    {Boolean(txError) && (
                        <div className="rounded-full bg-red-50 p-4 mt-8 border-l-4 border-r-4 border-red-400 bg-red-50 p-4">
                            <div className="text-sm text-red-700 text-center">
                                {txError || 'Transaction failed'}
                            </div>
                        </div>
                    )}
                    {Boolean(statusMessage) && (
                        <p className="mt-2 text-sm font-semibold text-gray-500 py-8">
                            {statusMessage}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}