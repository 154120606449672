import { DAppKitProvider } from '@vechain/dapp-kit-react';
import type { WalletConnectOptions } from '@vechain/dapp-kit';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { NETWORK, NODE_URL, WALLET_CONNECT_PROJECT_ID } from './config';
import Steps from "./Steps"
import { ThemeProvider } from "@/components/theme-provider"
import { useTheme } from "next-themes"
import { Disconnect } from './common/Disconnect';

const walletConnectOptions: WalletConnectOptions = {
    projectId: WALLET_CONNECT_PROJECT_ID,
    metadata: {
        name: 'join.vechain.vet',
        description: 'Join Vechain & Web3 For Better.',
        url: window.location.origin,
        icons: [`https://vet.domains/assets/walletconnect.png`],
    },
};

export default function App() {
    return (
        <ThemeProvider
            attribute="class"
            defaultTheme="system"
            enableSystem
            disableTransitionOnChange
        >
            <DappKitContainer>
                <div className='bg-background w-full h-screen sm:w-auto sm:h-auto sm:shadow-xl sm:max-w-4xl sm:m-auto sm:mt-8'>
                    <div className='opacity-50 hover:opacity-75 transition-all flex justify-end'><Disconnect /></div>
                    <BrowserRouter future={{ v7_startTransition: true }}>
                        <Routes>
                            <Route path="/:activity" element={<Steps />} />
                            <Route path="*" element={<Steps />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </DappKitContainer>
        </ThemeProvider>
    );
}

function DappKitContainer({ children }: { children: React.ReactNode }) {
    const { resolvedTheme: theme } = useTheme()

    return (
        <DAppKitProvider
            nodeUrl={NODE_URL}
            genesis={NETWORK}
            usePersistence
            walletConnectOptions={walletConnectOptions}
            themeMode={theme === 'dark' ? 'DARK' : 'LIGHT'}
        >
            {children}
        </DAppKitProvider>

    )
}