import { useWalletName } from '../hooks/useWalletName';
import { useWallet } from "@vechain/dapp-kit-react"
import { WalletButton } from '@vechain/dapp-kit-react';

export function Disconnect() {
    const wallet = useWallet()
    const { name } = useWalletName(wallet.account)

    return (
        <div className="p-6 flex justify-center h-16">
            {wallet.account && <div className="flex justify-center"><WalletButton address={name ? name.length <= 8 ? name.replace('.vet', ' .vet') : name : wallet.account} /></div>}
        </div>
    )
}