import React from 'react'
import VeWorld from './VeWorld'
import AppHub from './AppHub'
import Connect from './Connect'
import ClaimName from './ClaimName'
import { useWallet } from '@vechain/dapp-kit-react'
import { useWalletName } from "~/src/hooks/useWalletName";

export default function Steps() {
    const hasVeworld = Boolean(window?.vechain?.isVeWorld)
    const { account } = useWallet()
    const { name } = useWalletName(account);

    if (!hasVeworld) {
        return <VeWorld />
    }
    
    if (!account) {
        return <Connect />
    }
    
    if (!name) {
        return <ClaimName />
    }

    return (
        <AppHub />
    )
}
