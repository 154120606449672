{
  "contracts": {
    "ensRegistry": {
      "address": "0xcBFB30c1F267914816668d53AcBA7bA7c9806D13"
    },
    "ensBaseRegistrarImplementation": {
      "address": "0xca1B72286B96F30391aBB96C7d5e3Bf2D767927d"
    },
    "ensDnsRegistrar": {
      "address": "0x0000000000000000000000000000000000000000"
    },
    "ensEthRegistrarController": {
      "address": "0xAA854565401724f7061E0C366cA132c87C1e5F60"
    },
    "ensNameWrapper": {
      "address": "0x67d8D01cF0d6d9ed2c120FfF1D4Fa86fC10C9D8e"
    },
    "ensPublicResolver": {
      "address": "0xA6eFd130085a127D090ACb0b100294aD1079EA6f"
    },
    "ensReverseRegistrar": {
      "address": "0x6878f1aD5e3015310CfE5B38d7B7071C5D8818Ca"
    },
    "ensBulkRenewal": {
      "address": "0x23aEe21815FDfcba86882c8b10502514a77eFd8A"
    },
    "ensDnssecImpl": {
      "address": "0x0000000000000000000000000000000000000000"
    },
    "ensUniversalResolver": {
      "address": "0x77fCCE52D4635F9a6a5E06e44aB05c0d5D96D187"
    },
    "multicall3": {
      "address": "0x736eAC86d704d8AD13Bb97628928c46dCb7Ad9ef"
    },
    "vetResolveUtils": {
      "address": "0xc403b8EA53F707d7d4de095f0A20bC491Cf2bc94"
    },
    "vetWeb2Domains": {
      "address": "0xABfaE1B040e0474a70b120Fc32d638edB01F4aE6"
    },
    "vetSignedRegistrarController": {
      "address": "0x5022d5709d1A29d777F6426C3131619c8dbD4BFf"
    }
  }
}