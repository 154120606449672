import { isMobile } from 'react-device-detect';

export default function Example() {
    return (
        <div className="bg-background">
            <div className="px-6 py-4 sm:px-6 sm:py-16 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <img src="/veworld.svg" className='w-32 h-32 m-auto pb-8' />
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                        Welcome to Vechain!
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-500">
                        To get you started, you need to install VeWorld, Vechain's official wallet.
                    </p>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-500">
                        It runs on all major platforms and serves as your entry point to your Vechain journey. You can install it as a browser extension or mobile app.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href={
                                isMobile
                                    ? `https://www.veworld.com`
                                    : "https://chromewebstore.google.com/detail/veworld/ffondjhiilhjpmfakjbejdgbemolaaho?hl=en-GB"
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                            className="w-full h-12 flex items-center justify-center rounded-full bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            {isMobile ? 'Download VeWorld Mobile' : 'Install VeWorld Extension Now'}
                        </a>
                    </div>

                    {isMobile && (
                        <div className="mt-6 flex items-center justify-center gap-x-6">
                            <a
                                href={
                                    isMobile
                                        ? `https://www.veworld.com/discover/browser/ul/${encodeURIComponent(location.href)}`
                                        : "https://chromewebstore.google.com/detail/veworld/ffondjhiilhjpmfakjbejdgbemolaaho?hl=en-GB"
                                }
                                target="_blank"
                                rel="noreferrer noopener"
                                className="w-full h-12 flex items-center justify-center rounded-full bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Open VeWorld Mobile
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
