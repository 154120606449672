import { useWalletModal } from "@vechain/dapp-kit-react"
import { Link2Icon as Icon } from "@radix-ui/react-icons"

export default function ConnectWallet() {
    const modal = useWalletModal()
    return (
        <div className="bg-background">
            <div className="px-6 py-4 sm:px-6 sm:py-16 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <Icon className='w-32 h-32 m-auto pb-8 text-cyan-600' />
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
                        Connect For Your Welcome-Gift
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-500">
                        Now that you've installed VeWorld, connect VeWorld to this onboarding dApp and claim your own .vet name as a welcome gift.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <button
                            type='button'
                            onClick={modal.open}
                            className="w-full h-12 flex items-center justify-center rounded-full bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                        >
                            Connect Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}