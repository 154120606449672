import { createContext, useContext, useMemo, useState, useCallback } from 'react'
import { useConnex } from "@vechain/dapp-kit-react"
import { Provider } from '@vechain/web3-providers-connex'
import { createPublicClient, createWalletClient, custom } from 'viem'
import { network } from '~/src/config'
import { GTLD, SPONSORSHIP_URL } from '~/src/config'
import { useToast } from "~/@/components/ui/use-toast"
import type { ClientWithEns, WalletWithEns } from '@ensdomains/ensjs/contracts'

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

interface EnsContextProps {
    client: WalletWithEns,
    publicClient: VetClientWithEns,
    gtld: string,
}

type VetClientWithEns = ClientWithEns & {
    contracts: { vetWeb2Domains: { address: string } }
}
const ensContext = createContext<EnsContextProps | undefined>(undefined)
export function ProvideEns({ children }: { children: React.ReactNode }) {
    const provides = useEns()
    return <ensContext.Provider value={provides}>{children}</ensContext.Provider>
}

export function useSponsorship() {
    return useContext(ensContext)
}

export function useEns() {
    const connex = useConnex()
    const { toast } = useToast()
    const [nonce, setNonce] = useState(0)

    const publicClient = useMemo(() => {
        return createPublicClient({
            chain: network,
            transport: custom(new Provider({ connex }))
        }) as unknown as VetClientWithEns
    }, [connex, nonce]);

    const client = useMemo(() => {
        return createWalletClient({
            chain: network,
            transport: custom(new Provider({
                connex,
                delegate: {
                    url: SPONSORSHIP_URL
                }
            }))
        }) as unknown as WalletWithEns
    }, [connex, nonce]);

    const refresh = () => setNonce(nonce + 1)

    const waitForTxId = useCallback(async (txId: string, options?: { successMessage?: string }) => {
        toast({ description: `Transaction ${txId.slice(0, 4)}..${txId.slice(-4)} sent, waiting for confirmation` })

        let txReceipt
        const tx = connex.thor.transaction(txId)
        do {
            await sleep(1000)
            txReceipt = await tx.getReceipt()
            if (txReceipt?.reverted) {
                toast({ description: `Transaction failed, please try again later` })
                return false
            }
            else if (txReceipt) {
                if (options?.successMessage) {
                    toast({ description: options.successMessage })
                }
                return true
            }
        } while (!txReceipt)

        return false
    }, [toast, connex])

    return {
        client,
        publicClient,
        gtld: GTLD,
        refresh,
        nonce,
        waitForTxId
    }
}
