import { mainnet, testnet } from './Networks'

export const WALLET_CONNECT_PROJECT_ID = process.env.WALLET_CONNECT_PROJECT_ID ?? '790f3d011ccbf6844052c5441aac23ab'
export const NETWORK = (process.env.NETWORK ?? 'test') as 'main' | 'test'
export const NODE_URL = process.env.NODE_URL ?? `https://node-${NETWORK}net.vechain.energy`
export const GTLD = 'vet'
export const SPONSORSHIP_URL = process.env.SPONSORSHIP_URL ?? 'https://sponsor-testnet.vechain.energy/by/90'
export const NETWORK_COIN_ID = 703 /* VET */
export const API_URL = process.env.API_URL ?? 'https://testnet.vet.domains'
export const network = NETWORK === 'test' ? testnet : mainnet
